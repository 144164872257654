import css from '../../css/C0533/ContactUs.module.css'
import React from 'react';
import Photo from '../../global/C533/Phone.jpg'
import Email from '../../global/C533/Email.jpg'
import FaceBook from '../../global/C533/FaceBook.jpg'
import Address from '../../global/C533/Address.png'
import { useState } from 'react'

import axios from 'axios'

import Logo from '../../../src/global/C533/AboutLogo.png'

const ContactUs: React.FC = () => {

    const [isMessageType, setMessageType] = useState<string[]>([])
    const [iscontectPerson, setContectPerson] = useState('')
    const [iscontectPhone, setContectPhone] = useState('')
    const [iscontectEmail, setContectEmail] = useState('')
    const [iscontectContent, setContectContent] = useState('')

    const [equipment, setEquipment] = useState(false);
    const [course, setCourse] = useState(false);
    const [posp, setPOSP] = useState(false);
    const [other, setOther] = useState(false)

    const handleMessageType = (name: string, value: boolean) => {
        setMessageType((prev) => {
            if (value) {
                // Add type if it doesn't already exist
                return [...prev, name];
            } else {
                // Remove type if it exists
                return prev.filter((type) => type !== name);
            }
        });
    };

    const sendEmailHandler = async() => {

        if (isMessageType.length === 0) {
            alert('請選擇相關事項')
            return
        }

        if (iscontectPerson === '' || iscontectPerson.length <= 0) {
            alert('請填寫聯絡人')
            return
        }

        if (iscontectPhone === '' || iscontectPhone.length <= 0) {
            alert('請填寫聯絡人電話')
            return
        }

        if ( iscontectEmail === '' || iscontectEmail.includes('@') === false || iscontectEmail.includes('.') === false) {
            alert('電郵格式不正確')
            return
        }


        if (iscontectContent === '' || iscontectContent.length <= 0) {
            alert('請填寫內容')
            return
        }

        const PostData ={
            items: isMessageType,
            contact_person: iscontectPerson,
            contact_phone: iscontectPhone,
            contact_mail: iscontectEmail,  
            content: iscontectContent
        }

        console.log(PostData)

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0533/SendMailTOHKRSU`, PostData);
            //const res = await axios.post(`http://192.168.68.167:8000/c0533/SendMailTOHKRSU`, PostData);

            if (res.status === 200) {
                //alert('成功發送')
                alert(`成功發送\n 相關事項: ${isMessageType}\n聯絡人: ${iscontectPerson}\n聯絡人電話: ${iscontectPhone}\n電郵: ${iscontectEmail}\n內容: ${iscontectContent}`)
                setContectPerson('')
                setContectPhone('')
                setContectEmail('')
                setContectContent('')
                setMessageType([])
            }

        } catch (e) {
            console.log(e)
        }
    }

    const content = (
        <section className={css.MainSection}>

            <div className={css.ContactUsBtnDiv2}>
                <a href="#from" >
                    <div className={css.ContactUsBtnDiv}>
                        聯絡我們
                    </div>
                </a>
            </div>

            <div className={css.ContactInfo}>
                <div className={css.EachContact}>
                    <img src={Email} alt="Email" />
                    <div className={css.ContactMessage}>
                        <p>hkrsu.asia@gmail.com</p>
                    </div>
                </div>
                <div className={css.EachContact}>
                    <img src={Photo} alt="Phone" />
                    <div className={css.ContactMessage}>
                        <p>(+852) 65801700</p>
                    </div>
                </div>
                <div className={css.EachContact}>
                    <img src={FaceBook} alt="FaceBook" />
                    <div className={css.ContactMessage}>
                        <p>www.facebook.com/hkrsu</p>
                    </div>
                </div>
                <div className={css.EachContact}>
                    <img src={Address} alt="Address" />
                    <div className={css.ContactMessage}>
                        <p>地址：葵涌大連排道83號 K83 15樓03室 (葵興港鐵站 E出口 步行5分鐘)</p>
                        <p>郵寄地址：將軍澳郵政局郵政信箱65217號</p>
                    </div>
                </div>
            </div>

            <div className={css.formDiv}>
                {/* <form id="from"> */}
                    <p>相關事項</p>
                    <br />

                    <div className={css.TwoInOne}>

                        <div className={css.TwoInOne_Form}>
                            <div className={css.FormTop}>
                                <input
                                    type="checkbox"
                                    checked={equipment}
                                    onChange={(e) => {
                                        setEquipment(e.target.checked);
                                        handleMessageType('equipment', e.target.checked);
                                    }}
                                />
                                <label>器材</label>
                            </div>

                            <div className={css.FormTop}>
                                <input
                                    type="checkbox"
                                    checked={course}
                                    onChange={(e) => {
                                        setCourse(e.target.checked);
                                        handleMessageType('course', e.target.checked);
                                    }}
                                />
                                <label>課程</label>
                            </div>

                            <div className={css.FormTop}>
                                <input
                                    type="checkbox"
                                    checked={posp}
                                    onChange={(e) => {
                                        setPOSP(e.target.checked);
                                        handleMessageType('表演或學校推廣', e.target.checked);
                                    }}
                                />
                                <label>表演或學校推廣</label>
                            </div>

                            <div className={css.FormTop}>
                                <input
                                    type="checkbox"
                                    checked={other}
                                    onChange={(e) => {
                                        setOther(e.target.checked);
                                        handleMessageType('其他', e.target.checked);
                                    }}
                                />
                                <label>其他</label>
                            </div>
                        </div>

                        <div className={css.TwoInOne_Logo}>
                            <img src={Logo} alt="Logo" />
                        </div>

                    </div>
                    <br />

                    <label>
                        <div className={css.textbox}>
                            <p>聯絡人</p>
                            <p className={css.required}>*</p>
                        </div>
                        <input type="text" name="name" placeholder="聯絡人姓名" className={css.inputTextBox} value={iscontectPerson} onChange={(e) => setContectPerson(e.target.value)} />
                    </label>

                    <br />

                    <label>
                        <div className={css.textbox}>
                            <p>聯絡人電話</p>
                            <p className={css.required}>*</p>
                        </div>
                        <input type="text" name="name" placeholder="聯絡人電話" className={css.inputTextBox} value={iscontectPhone} onChange={(e) => setContectPhone(e.target.value)} />
                    </label>

                    <br />

                    <label>
                        <div className={css.textbox}>
                            <p>電郵</p>
                            <p className={css.required}>*</p>
                        </div>
                        <input type="text" name="name" placeholder="電郵" className={css.inputTextBox} value={iscontectEmail} onChange={(e) => setContectEmail(e.target.value)} />
                    </label>

                    <br />

                    <label>
                        <div className={css.textbox}>
                            <p>內容</p>
                            <p className={css.required}>*</p>
                        </div>
                        <textarea name="name" rows={10} cols={30} placeholder="填寫內容" className={css.AllContent} value={iscontectContent} onChange={(e) => setContectContent(e.target.value)} />
                    </label>

                    <br />

                    <input type="submit" value="提交" onClick={() => sendEmailHandler()}/>
                {/* </form> */}
            </div>

        </section >
    )
    return content
}
export default ContactUs