import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { IC0553WebsiteImage } from "../../Interface/interface";
import { NextArrow, PrevArrow } from './CustomArrows'; // Adjust the path as necessary

// CSS imports
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import css from '../../css/C0533/Store.module.css';

interface EachProductProps {
    data: IC0553WebsiteImage[]; // Correctly type the props to expect an array
}

interface ArrowProps {
    className?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
}

const EachProduct = ({ data }: EachProductProps) => {
    const [slideIndex, setSlideIndex] = useState(0);
    const sliderRef1 = useRef<Slider | null>(null);
    const sliderRef2 = useRef<Slider | null>(null);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current: number, next: number) => setSlideIndex(next),
        afterChange: (current: number) => setSlideIndex(current),
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };

    const subsettings = {
        dots: false,
        infinite: true,
        speed: 500,
        centerMode: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        beforeChange: (current: number, next: number) => setSlideIndex(next),
        afterChange: (current: number) => setSlideIndex(current),

    };

    useEffect(() => {
        if (sliderRef1.current && sliderRef2.current) {
            sliderRef1.current.slickGoTo(slideIndex);
            sliderRef2.current.slickGoTo(slideIndex);
        }
    }, [slideIndex]);

    return (
        <>
            {data.length > 1 ? (
                <div className={css.EachProduct}>
                    <div className={css.MainPhoto}>
                        <Slider ref={sliderRef1} {...settings}>
                            {data.map((item) => (
                                <div key={item.web_item_id} className={css.ImageSliderImg}>
                                    <img src={item.item_pic_url} alt={item.item_desc} />
                                </div>
                            ))}
                        </Slider>
                    </div>
                    <div className={css.ProductSlider}>
                        <Slider ref={sliderRef2} {...subsettings}>
                            {data.map((item) => (
                                <div key={item.web_item_id} className={css.ImageSliderImg}>
                                    <img src={item.item_pic_url} alt={item.item_desc} />
                                </div>
                            ))}
                        </Slider>
                    </div>
                    <div className={css.ProductName}>
                        <p className={css.ProductName2}>{data[slideIndex].item_desc2}</p>
                        <p className={css.ProductPrice}>{data[slideIndex].item_desc}</p>
                    </div>
                </div>
            ) : (
                <div className={css.EachProduct}>
                    <div className={css.MainPhoto2}>
                        <div className={css.OneImageSliderImg}>
                            <img src={data[0].item_pic_url} alt={data[0].item_desc} />
                        </div>
                    </div>
                    <div className={css.ProductName}>
                        <p className={css.ProductName2}>{data[0].item_desc2}</p>
                        <p className={css.ProductPrice}>{data[0].item_desc}</p>
                    </div>
                </div>
            )}
        </>
    );
};

export default EachProduct;
